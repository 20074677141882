import React from 'react'

import Layout from '../components/layout'
import { graphql } from 'gatsby'
import styled from 'react-emotion'
import COLOR_PALLETE from '../utils/colors'

const Info = styled('div')`
  margin: 30px 0;
  border-left: 3px solid ${COLOR_PALLETE.ACCENT};

  & h4 {
    text-transform: none;
    color: ${COLOR_PALLETE.BLACK};
    padding: 5px;
    margin: 5px;
  }
  & p {
    width: 95%;
    margin: 0 auto;
  }
`
const AboutPage = ({ data }) => {
  return (
    <Layout>
      <Info>
        <h4>About me</h4>
        <p>
          I am <a href="https://www.kartikmalik.com"> Kartik Malik </a>,
          Software developer from Goa, India. In love with JavaScript because of
          its simplicity. I build Frontend, Fullstack applications and keep on
          learning new things.
        </p>
      </Info>
      <Info>
        <h4> Importance of this blog</h4>
        <p>
          This blog will act as a diary of things I know, want to learn. I will
          write about my experiences on a regular basis. By doing this, my goal
          is to improve as a developer, help someone out with the knowledge I
          possess.
        </p>
      </Info>
      <Info>
        <h4>Reach out to me</h4>
        <p>
          In case you need some help or just want to talk tech reach out to me
          <a href="https://www.kartikmalik.com/contact"> here </a>.
        </p>
      </Info>
    </Layout>
  )
}

//TODO: fetch data from JSON
export default AboutPage

// export const query = graphql`
//   {

//   }
// `
